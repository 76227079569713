html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  font-family: 'Lato', sans-serif;
}
  
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 15px 20px;
  border-bottom: 1.5px solid #59846A;
  background-color: #9BBFAA;
}

header img {
  max-width: 100px;
  margin-left: 0;
}
  
nav ul {
  list-style: none;
  display: flex;
}
  
nav li {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
  
nav a {
  text-decoration: none;
  color: #375945;
}

.login-button {
  color: #375945;
  background-color: #D7EADF;
  margin-top: 15px;
}

.login-button:hover {
  color: #375945;
  background-color: white;
}

.tagline {
  color: #375945;
  font-size: 3.5vw;
  font-weight: 725;
  margin-bottom: 30px;
}

.free-trial-button {
  padding: 10px 30px;
  background-color: #375945;
  border: 1px solid #375945;
  color: white;
  cursor: pointer;
  border-radius: 100px;
  font-size: 1.25rem;
  text-decoration: none;
}
  
.enter-email {
  color: #4a6d58;
  font-size: 1.8vw !important;
  font-family: 'Mulish';
}

#waitlist-email-form {
  margin-top: -15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#waitlist-email-form label {
  margin-bottom: 0.75rem; /* Adjust spacing between label and input */
}

.email-link {
  color: #375945;
}

.waitlist-label {
  color: #333; /* Darker color */
  font-weight: 800; 
  font-family: 'Mulish', sans-serif
}
  
#waitlist-email-form input[type=email] {
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #323232;
  border-radius: 10px;
  flex-grow: 1;
  margin-right: 1rem;
  margin-left: 1rem;
  width: 70%;
}
  
  .waitlist-email-form-button {
    padding: 10px;
    background-color: #375945;
    border: 1px solid #375945;
    color: white;
    cursor: pointer;
    border-radius: 10px;
    margin-bottom: 16px;
  }
  
  #waitlist-email-form button:hover {
    background-color: #375945;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  }

#email-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#email-form label {
  margin-bottom: 0.75rem; /* Adjust spacing between label and input */
}
  
#email-form input[type=email] {
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #323232;
  border-radius: 10px;
  flex-grow: 1;
  margin-right: 1rem;
  width: 70%;
}
  
  #email-form button {
    padding: 10px;
    padding-right: 16px;
    padding-left: 16px;
    margin-bottom: 1rem;
    background-color: #375945;
    border: 1px solid #375945;
    color: white;
    cursor: pointer;
    border-radius: 10px;
  }
  
  #email-form button:hover {
    background-color: #375945;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  }

  .opening-content-container {
    display: flex;
    justify-content: space-between;
    padding: 40px 0; /* Add padding as needed */
    flex-direction: row;
    background-color: #9BBFAA;
  }

  .medium-text {
    font-size: 1.8em; 
  }
  .small-text {
    font-size: 1.5em;
}

  .left-content {
    flex: 1; /* This will allow the left content to grow and take up the space */
    padding-left: 1.5rem;
    font-weight: light;
  }
  
.right-content {
    flex: 1; /* This will allow the right content to grow and take up the space */
    display: flex;
    justify-content: flex-end; /* This will align the image to the right */
}
  
/* Ensure the image doesn't exceed its container */
.right-content img {
    max-width: 65%;
    max-height: 95%;
    height: auto;
    padding-right: 3.5rem;  
}

@media (max-width: 480px) { /* A common width for very small devices like phones */
  .content-container {
    display: flex;
    justify-content: space-between;
    padding: 4px 0; /* Add padding as needed */
    flex-direction: column;
  }

  /* Stack the third-content-container */
  .third-content-container {
    flex-direction: column;
    align-items: stretch; /* Ensures full-width stacking */
  }

  /* Stack the sections within third-content-container */
  .third-content-container .left-side,
  .third-content-container .right-side {
    width: 100%; /* Take full width */
    padding: 10px; /* Adjust padding as needed */
    box-sizing: border-box; /* Ensures padding doesn't affect width */
  }

  /* Stack the fourth-content-container */
  .fourth-content-container {
    display: flex;
    flex-direction: column;
    align-items: stretch; /* Ensures full-width stacking */
  }

  /* Stack the sections within fourth-content-container */
  .fourth-content-container .left-side,
  .fourth-content-container .right-side {
    width: 100%; /* Take full width */
    padding: 10px; /* Adjust padding as needed */
    box-sizing: border-box; /* Ensures padding doesn't affect width */
  }

  /* Ensure images scale properly */
  .right-content img,
  #jumpingladyside img,
  .homeworkicon {
    width: 100%;
    height: auto;
  }

  /* Adjust font sizes for better readability on small screens */
  .tagline {
    font-weight: bold;
    font-size: 2rem; /* Adjust based on your design needs */
  }

  .enter-email {
    font-size: 1.2rem;
  }

  .lighter-bold {
    font-size: 1rem;
  }

  .yosa-for {
    padding-top: 10px;
    font-size: 1.25rem;
  }

  .login-button {
    margin-top: 18px;
  }

  #email-form input[type="email"] {
    width: 100%; /* Reduce the width to make it smaller */
    font-size: 0.8rem; /* Reduce the font size */
    padding: 8px; /* Adjust padding to make it fit better */
  }

  #email-form button {
    width: 100%; /* Adjust width of the button */
    font-size: 0.8rem; /* Reduce the font size */
    padding: 8px; /* Adjust padding for the button */
  }

  #email-form .small-text {
    font-size: 0.8rem; /* Make the font size smaller */
    line-height: 1.2; /* Adjust line height if necessary */
    margin-top: 10px; /* Add some margin to separate from the button above */
    display: block; /* Ensure it takes up full width */
    text-align: center; /* Center the text */
    padding-top: 8px;
  }

  .opening-content-container {
    display: flex;
    flex-direction: column; /* Ensures stacking on small screens */
    align-items: center;
    height: auto; /* Allow it to grow based on content */
    min-height: 500px; /* Set a minimum height */
    background-color: #9BBFAA; /* Ensure it takes up background space */
}

  .right-content {
    max-width: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-top: 10px;
    height: 100%;
  }

  .right-content img {
    width: 80%; /* Increase the size of the image */
    max-width: 300px; /* Set a larger max-width for the image */
    height: auto; /* Maintain the aspect ratio */
    margin: 0;
    padding-top: 8px;
    padding: 0;
    display: block;
}
  .footer {
    margin-top: auto;
    padding: 0;
    width: 100%;
    box-sizing: border-box;
  }
}

.lighter-bold {
  font-weight: 650; /* Adjust the weight as needed */
}

.even-lighter-bold {
  font-weight: 600; /* Adjust the weight as needed */
}

.second-content-contatiner {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  
  align-items: center;
}

.top-content {
  display: flex;
  
  justify-content: center;
  align-items: center;
  margin-top: 1rem;  
  padding: 10px;
  color: #323232;
}

.middle-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.footer {
  background-color: #FFEBA3;
  padding: 20px 0;
  text-align: center;
  border-top: 1px solid #e7e7e7;
  position: relative;
  bottom: 0;
  width: 100%;
  font-family: 'Mulish', sans-serif;
  font-weight: 100;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
}

.footer-content p {
  margin: 0 0 10px 0;
  color: #333;
}

.footer-bottom {
  background-color: #FFEBA3;
  padding: 20px 0;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
  font-family: 'Mulish', sans-serif;
  font-weight: 800;
  gap: 10px;
}

.footer-bottom .email-link {
  color: inherit;
  text-decoration: none;
  margin-right: 30px;
  
}

.footer-bottom .linkedin-icon {
  width: 24px;
  height: 24px;
  gap: 10px;
}

.footer-content nav ul {
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
  display: flex;
  justify-content: center;
  
}

.footer-content nav ul li {
  margin: 0 15px;
  
}

.footer-content nav ul li a {
  color: #333;
  text-decoration: none;
}

.footer-content nav ul li a:hover {
  text-decoration: underline;
}

.box {
  width: 220px;
  height: 110px;
  border: 1px solid #323232;
  margin: 20px 30px 20px 30px;
  padding: 10px;
  float: left;
  background-color: #D7EADF;
  border-radius: 10px;
  color: #323232;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;  
}

.bottom-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  padding-bottom: 35px;
}

.bottom-content img {
  max-width: 80px;
  width: auto;
  height: auto;
  padding: 0.8rem;
}

/* Improvement Container */
.improvement-container {
  text-align: center;
  padding: 50px 20px;
  background-color: #f9f5f2; /* Light beige background */
  border-radius: 20px; /* Rounded corners for the entire section */
}

/* Stats Title */
.improvement-title {
  font-size: 1.8rem;
  color: #333333; /* Dark text */
  font-family: 'Mulish';
  margin-bottom: 40px;
  font-weight: 600;
}

/* Stats Content Layout */
.improvement-content {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap; /* Ensure responsiveness on smaller screens */
}

/* Individual Stat */
.stat {
  text-align: center;
  max-width: 250px;
}

/* Stat Circle */
.stat-circle {
  width: 120px;
  height: 120px;
  border: 5px solid #000000; /* Circle border */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
}

/* Stat Number inside Circle */
.stat-number {
  font-size: 1.5rem;
  font-weight: 700;
  color: #000000; /* Black text for numbers */
}

/* Stat Description */
.stat-description {
  font-size: 0.95rem;
  color: #333333; /* Dark text */
  line-height: 1.4;
}

/* Highlight "10 weeks" layout */
.stat-unit {
  font-size: 1rem;
  font-weight: 400;
  color: #333333;
}

/* Responsive Design */
@media (max-width: 768px) {
  .stats-content {
    flex-direction: column; /* Stack stats vertically */
    gap: 20px; /* Reduce spacing */
  }
}


.third-content-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  background-color: #375945;
}

.left-side {
  background-color: #59846A;
}

#description {
  font-size: larger;
  font-family: 'Lato', sans-serif;
}

#jumpingladyside {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 50px;

}

#jumpingladyside img {
  max-width: 325px;
  padding: 30px;
  height: auto;
}

.homework-container {
  display: flex;
  align-items: center; /* Vertically center the content */
  justify-content: center; /* Center horizontally */
  height: 100%; /* Ensure the container takes the full height */
  background-color: #375945;
  color: white;
  padding: 20px;
}

.homeworkicon {
  max-width: 175px;
}

.right-side {
  background-color: #375945;
  color: white;
  padding: 20px;
}

.fourth-content-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

#left {
  background-color: #375945;
  color: white;
  padding: 20px;
  flex-grow: 1;
}

#right {
  background-color: #59846A;
}

.bold-text {
  font-weight: bold;
}

.highlight-text {
  color: #FFEDAC;
}

.highlight-title-text {
  color: #FFEBA3;
  font-weight: 600;
}

.reference-link {
  color: #7C9F8A;
  text-decoration: underline;
  font-size: medium;
}

.reference-link:hover {
  color: #9BBFAA;
  transition: color 0.3s ease;
}

.custom-carousel {
  max-width: 200px; /* Adjust this value as needed */
  margin: 0 auto; /* Center the carousel horizontally */
}

.custom-carousel img {
  max-width: 100%;
  height: auto;
}




/* Below styles are for provider dashboard */

.provider-header {
  background-color: #f5f2e8;
}

.logo {
  margin-left: 30px;
}

.provider-dash {
  background-color: #f5f2e8;
  min-height: 89.55vh;
}

.provider-welcome {
  color: #375945;
  margin-left: 5vw;
  padding: 30px 52px;
  margin: 0;
}

.tables-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
}

.table-left {
  margin-right: 30px;
  width: 55vw;
}

.patients-table, .worksheet-table {
  background-color: #9BBFAA;
  border-radius: 15px;
}

.table-right {
  margin-left: 30px;
  width: 30vw;
}

.button {
  margin-bottom: 15px;
  font-weight: 650;
  color: #375945;
  background-color: #D7EADF;
}

/* Below styles are for sign up page */

.signup-container {
  display: flex;
  flex-direction: row;
}

#signuplogo {
  width: 25%;
  padding: 35px;
}

.signup-right {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  height: 100vh;
  margin-right: 30px;
}

.signup-left {
  background-color: #aeaca6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65vw;
}

#signupimage {
  width: 100%;
  height: 100%;
}

/* Below styles are for TEST provider dashboard */

.sidebar {
  background-color: #375945;
}

.wholescreen {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.patients-table {
  margin-left: 15px;
  margin-top: 25px;
}

.worksheet-table {
  margin-right: 15px;
}

.email-form-group {
  display: flex;
  align-items: center;
}

.waitlist-form {
  margin-top: 20px; /* Add spacing above the waitlist form */
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.waitlist-input {
  margin-right: 10px;
}

.email-formbutton {
  padding: 5px 10px;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.email-formbutton:hover {
  background-color: #0056b3;
}

.waitlist-form .input-group {
  display: flex;
  align-items: center;
  gap: 10px;
  
}

.lower-email {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  gap: 10px; /* Add spacing between input and button */
}

.waitlist-form .lower-email {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Add spacing between input and button */
}

.waitlist-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  
}

.waitlist-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.waitlist-button:hover {
  background-color: #555;
}

.footer-text {
  background-color: #FFEBA3;
  padding: 0px 0;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
  font-family: 'Mulish', sans-serif;
  font-weight: 500;
  font-size: 17px;
}