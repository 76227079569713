.pricing-container {
    font-family: 'Mulish', sans-serif;
    background-color: #f9f9f9;
    text-align: center;
}

.pricing-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    background-color: white;
    border-color: #375945;
    border-width: 3px;
}

.pricing-header h1 {
    font-size: 2.5rem;
    color: #375945;
    margin-bottom: 1rem;
}

.pricing-header p {
    font-size: 1.1rem;
    color: #d9534f;
    /* Red for the sale message */
    font-weight: bold;
}

.billing-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.billing-toggle span {
    font-size: 0.9rem;
    color: #555;
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 24px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #375945;
}

input:checked+.slider:before {
    transform: translateX(26px);
}

.pricing-cards {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
    padding-bottom: 2rem;
}

.pricing-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 6rem;
    width: 335px;
    height: 460px;
    text-align: left;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.pricing-card h2 {
    font-size: 1.4rem;
    color: #375945;
    margin-bottom: 1rem;
}

.pricing-price {
    font-size: 1.7rem;
    font-weight: bold;
    color: #375945;
    margin: 1rem 0;
}

.original-price {
    text-decoration: line-through;
    font-size: 1.2rem;
    color: #a5a5a5;
    margin-right: 0.5rem;
}

.discounted-price {
    color: #59846a;
    /* Highlight sale price in red */
}

.pricing-details {
    font-size: 1.05rem;
    color: #555;
    margin-top: 0.3rem;
    margin-bottom: 1.7rem;
}

.pricing-features {
    list-style-type: disc;
    padding-left: 20px;
    text-align: left;
}

.pricing-features li {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 20px;
}

.credit-card-text {
    font-size: 0.9rem;
    color: #a5a5a5;
    margin: 0;
    padding: 0;
    position: relative;
    top: 13px;
}

.pricing-button {
    background-color: #375945;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
}

.pricing-button:hover {
    background-color: #2c4737;
}

@media (max-width: 768px) {
    .pricing-cards {
        flex-direction: column;
        align-items: center;
    }
}