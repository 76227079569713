.footer {
    z-index: 100;
    background-color: #FFEBA3;
    color: #343434;
    text-align: center;
    padding: 10px 20px;
    border-width: 0;
    font-size: 14px;
    width: 100%;
    position: relative;
    padding-top: 30px;
}

.footer a {
    color: #1890ff;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

.app-layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1;
}