.share-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .share-modal-content {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    max-width: 600px;
    width: 90%;
    max-height: 90%;
    overflow: scroll;
    text-align: center;
    position: relative;
  }
  
  .share-modal-close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
  }

  .share-modal-content h2 {
    font-size: 2rem;
    color: #375945;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  
  .share-disclaimer {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 1rem;
  }
  
  .share-assignment-list {
    margin: 1rem 0;
    max-height: 300px;
    overflow-y: auto;
    text-align: left;
  }

  .share-assignment-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.share-assignment-info {
    flex: 1; /* Make the info take the remaining width */
    display: flex;
    justify-content: space-between; /* Spread content to edges */
    align-items: center;
}

.share-assignment-info p {
    margin: 0;
    flex: 1; /* Make title stretch to occupy available space */
}

.auto-resize-textarea {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none; /* Prevent manual resizing */
  overflow-y: auto; /* Enable scrolling */
  min-height: 50px; /* Minimum height */
  max-height: 200px; /* Limit max height */
  margin-top: 10px;
  margin-bottom: 10px;
}

.share-button {
    background-color: #59846a; /* Default green */
    color: white;
    border: none;
    border-radius: 15px;
    padding: 0.2rem 1.5rem;
    cursor: pointer;
    margin-left: auto; /* Push the button to the right */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .share-button.shared {
    background-color: #FFEBA3; /* Yellow for shared state */
    color: #333; /* Darker text for contrast */
  }
  
  .share-button:hover {
    background-color: #375945; /* Darker green on hover */
  }
  
  .share-button.shared:hover {
    background-color: #FFDD64; /* Darker yellow on hover */
  }  
  
  .go-to-assignment-builder-button {
    background-color: #375945;
    color: white;
    border: none;
    border-radius: 25px;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    cursor: pointer;
  }
  
  .go-to-assignment-builder-button:hover {
    background-color: #2c4737;
  }
  