.element-preview-header {
    font-size: 20;
    font-family: 'Mulish';
    font-weight: bolder;
    color: '#323232';
}

.element-preview {
    font-size: 16;
    font-family: 'Mulish';
    font-weight: bold;
    color: '#323232';
}