.infinite-ticker {
  text-align: center;
  position: relative;
  overflow: hidden;
}

.ticker-row {
  white-space: nowrap;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.ticker-row-top .ticker-track {
  animation: scrollRight 35s linear infinite;
}

.ticker-row-bottom .ticker-track {
  animation: scrollLeft 35s linear infinite;
}

.ticker-track {
  display: inline-flex;
  white-space: nowrap;
  padding-top: 1rem;
}

.ticker-item {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 12px;
  padding: 1rem;
  margin: 0 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15), 0 2px 8px rgba(0, 0, 0, 0.08);
  text-align: center;
  width: 130px;
}

.ticker-item img {
  width: auto; /* Maintain aspect ratio */
  height: 50px; /* Set a fixed height */
  max-width: 70%; /* Prevent overflow */
  object-fit: contain; /* Ensure the image scales properly inside its container */
  margin-bottom: 0.5rem;
  border-radius: 0;
}


.icon-title {
  font-size: 0.9rem;
  color: #375945;
  font-weight: 500;
}

/* Keyframe animations */
@keyframes scrollRight {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes scrollLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
