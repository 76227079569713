.product-text {
    flex: 1;
    font-family: 'Mulish';
    color: #375945;
}

/* File: styles/FeaturesSection.css */

.features-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch; /* Makes left and right sections the same height */
    padding: 4rem;
    gap: 2rem;
  }
  
  .features-left {
    flex: 1; /* Left side takes up equal space */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensures text and accordion take up the height */
  }
  
  .assignment-toolkit-text {
    margin-bottom: 2rem;
  }
  
  .assignment-toolkit-text h2, .assignment-builder-text h2, .meal-donations-text h2 {
    font-size: 2rem;
    color: #375945;
    font-weight: 900;
  }
  
  .assignment-toolkit-text p, .assignment-builder-text p, .meal-donations-text p {
    font-size: 1rem;
    color: #6b8068;
    line-height: 1.6;
  }
  
  .example-text {
    font-weight: bold;
    color: #375945;
  }
  
  .features-right {
    flex: 1; /* Right side takes up equal space */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media (min-width: 1024px) {
    .custom-carousel {
      height: 900px;
    }
  }

  .assignment-builder-text {
    margin: 0rem 4rem 2rem 4rem;
  }

  .assignment-builder img {
    width: 70%;
    height: auto;
  }

  .meal-donations {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 4rem;
  }

  .meal-donations img {
    height: 400px;
    width: auto;
    margin-left: 80px;
  }

/* File: src/styles/ToolkitAccordion.css */
.toolkit-accordion {
    text-align: center;
    padding: 2rem;
  }
  
  .product-accordion {
    max-width: 800px;
    width: 450px;
    margin: 0 auto;
    text-align: left;
  }
  
  .accordion-item {
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    transition: box-shadow 0.3s ease;
  }
  
  .accordion-item:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  .accordion-item.active .accordion-header h3 {
    font-weight: bold;
    color: #375945;
  }
  
  .product-accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  
  .product-accordion-header h3 {
    font-size: 1.2rem;
    margin: 0;
  }
  
  .product-accordion-header span {
    font-size: 1.5rem;
    color: #375945;
  }
  
  .product-accordion-content {
    padding: 1rem;
    border-top: 1px solid #e0e0e0;
    background-color: #f9f9f9;
  }

  @media (max-width: 768px) {
    .assignment-builder {
      display: flex;
      flex-direction: column;
      align-items: center; /* Center content horizontally */
      justify-content: center; /* Center content vertically (if necessary) */
      text-align: center; /* Center text inside the section */
      padding: 1.5rem 0.5rem; /* Add padding around the section */
    }

    .assignment-builder-text {
      max-width: 700px; /* Constrain the width for readability */
    }
    .hero-images {
      display: flex;
      flex-direction: column;
      padding: 1rem;
    }
    
    .hero-images img {
      width: 100%;
      height: auto;
    }

    .features-section, .meal-donations {
      flex-direction: column;
      padding: 2rem;
    }

    .product-accordion {
      width: 100%;
    }

    .assignment-toolkit-text h2, .assignment-builder-text h2, .meal-donations-text h2 {
      margin-bottom: 2rem;
    }

    .assignment-builder-text p, .assignment-builder-text h2 {
      text-align: center;
      margin: 0rem -2rem;
    }

    .meal-donations-text p, .meal-donations-text h2 {
      text-align: center;
      margin: 0rem -3.75rem;
    }

    .meal-donations img {
      margin-left: 0;
    }

    .meal-donations img, .assignment-builder-text p, .meal-donations-text p {
      margin-top: 2rem;
    }
  }
