.element-editor {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    align-items: flex-start;
    height: auto; /* Allow height to adjust based on the textarea */
    overflow: visible;
}

.element-input-full-ta {
    width: 95%; /* Make the textarea fill the width of the parent */
    min-height: 35px; /* Minimum height */
    height: 50px;
    padding: 10px;
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    resize: vertical; /* Enable vertical resizing */
    max-height: 200px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box; /* Include padding and border in sizing */
    overflow-wrap: break-word;
    white-space: pre-wrap;
    text-align: left;
}

.element-input-full {
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 90%; /* Takes up full width of the container */
    height: 100%; /* Ensures it fills the height of its parent */
    min-height: 45%;
    box-sizing: border-box; /* Includes padding in width/height */
    outline: none; /* Removes the default focus outline */
    color: #323232;
    align-items: left;
    margin-top: 10px;
}

.element-input-full:focus {
    border-color: #59846A; /* Add a green border when focused */
    box-shadow: 0 0 5px rgba(89, 132, 106, 0.5); /* Optional: subtle glow effect */
}

.element-editor-checkbox {
    display: flex;
    flex-direction: row;
    gap: 10px; /* Space between checkbox and input */
    width: 90%; /* Restrict to 90% of the parent container */
    height: auto; /* Adjust height naturally */
    align-items: center; /* Vertically align items to the center */
    justify-content: flex-start; /* Align items horizontally to the left */
    box-sizing: border-box; /* Include padding and border in the width */
    min-width: 300px; /* Optional: Add a minimum width to prevent extreme squishing */
}

.element-editor img {
    max-width: 80%; /* Ensures the image does not exceed the container width */
    object-fit: contain; /* Ensures the image fits nicely within the container */
    border-radius: 0px;
    align-self: left; /* Centers the image in the parent container */
}

.element-image-mood {
    margin-top: 25px;
    max-height: 40px;
}

.element-image-slider {
    max-height: 25px;
}

.slider-text-container {
    position: relative; /* Makes the container a reference point */
    display: flex; /* Enables flexbox for alignment */
    justify-content: center; /* Centers the text horizontally */
    align-items: center; /* Centers the text vertically */
    margin-top: 20px;
    width: 100%;
}
  
.slider-text {
    position: relative; /* Keeps the text relative to its normal flow */
    font-size: 14px;
    font-weight: bold;
    color: #333; /* Adjust text color as needed */
    border-radius: 4px; /* Optional: Add rounded edges for style */
    white-space: nowrap; /* Prevent text wrapping */
    z-index: 2; /* Ensure it appears above other elements */
}