/* ClientDetail.css */
html, body, #root {
    height: 100%; /* Ensures the entire page takes up full height of the viewport */
    margin: 0;
}

.client-detail {
    display: flex;
    height:100%;
  }
  
  .sidebar {
    height: 100%;
    width: 200px;
  }
  
  .client-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: 'white';
    padding: 0px;
    overflow-y: auto;
  }

  .client-content-inner {
    padding: 40px;
    padding-top: 10px;
  }
  
  .client-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .client-header h1 {
    font-size: 2rem;
    font-weight: bold;
    color: #375945;
    font-family: 'Mulish';
  }
  
  .client-header p {
    font-size: 1.1rem;
    color: #375945;
    font-family: 'Mulish';
  }
  
  .client-header button {
    background-color: #59846A;
    border-color: #59846A;
    font-size: 1rem;
    padding: 8px 16px;
  }
  
  .chart-container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    flex-grow: 1;
  }

  .chart-selector {
    margin-bottom: 15px;
  }

  .chart-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Ensures content stretches to full height */
  }

  .chart-wrapper h3 {
    font-family: 'Mulish';
    font-size: 1.6rem;
    font-weight: bold;
  }

  .activity-title {
    font-family: 'Mulish';
    font-size: 1.6rem;
    font-weight: bold;
  }
  
  .chart-container h3 {
    font-size: 1.2rem;
    font-family: 'Mulish';
    font-weight: bold;
  }
  
  .ant-tabs-tab {
    color: #375945 !important;
    font-size: 1.1rem;
    font-family: 'Mulish';
    font-weight: bold;
  }

  .ant-tabs-tab:hover {
    color: #59846A !important; /* Change hover color */
  }

  .assignmentbutton {
    background-color: white;
    margin-top: 1rem;
    color: black;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    font-size: 1rem;
    font-family: 'Mulish';
    padding: 8px 16px;
    display: flex;
    align-items: center; /* Vertically centers the text */
    justify-content: center; /* Horizontally centers the text */
    height: 40px; /* Adjust the height as needed */
  }
  
  .assignmentbutton:hover {
    background-color: #59846A !important;
    color: white !important;
  }

  /* Styling for the action buttons (View Response, Unsend) */
.assignment-action-button {
    font-size: 1rem !important; /* Ensure consistent font size */
    text-decoration: underline !important; /* Underline the text */
    color: #A68200 !important; /* Same color as the assignment titles */
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .assignment-action-button:hover {
    color: #413300 !important; /* Custom hover color */
    text-decoration: underline; /* Keep underline on hover */
  }

  .title {
    font-size: 1.76rem;
    font-weight: bold;
    color: #375945;
    font-family: 'Mulish';
    margin-bottom: 20px;
  }
  
  .details {
    font-size: 0.9rem;
    font-weight: light;
    color: 'black';
    font-family: 'Mulish';
  }
  .client-info {
    display: flex;
    flex-direction: column;
  }

  .emergency-contacts, .schedule {
    margin-top: 20px;
  }

  .clientdetail-assignments-header {
    display: flex;
    gap: 30px;
    justify-content: left;
    align-items: left;
    margin-bottom: 1rem;
    margin-top: 1rem;
    margin-left: 1rem;
  }

  .assignments-filter-button {
    background-color: #A68200;
    color: white;
    border: none;
    border-radius: 30px;
    font-size: 0.9rem;
    font-family: 'Mulish';
    font-weight: 600;
    cursor: pointer;
    padding: 0rem 2rem;
    margin-bottom: 1rem;
  }
