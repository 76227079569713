/* Sidebar Styling */  
  .sidebar .ant-menu-item-selected {
    background-color: #2c4737 !important; /* Darker green for selection */
  }
  
  /* Header Styling */
  .ant-layout-header {
    background-color: white;
    color: #375945;
    font-family: "Mulish", sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 2rem;
  }
  
  /* Overview Cards */
  .overview-card {
    flex: 1;
    background-color: #5E5E5E; /* Light yellow */
    color: #FFEBA3;
    border-radius: 12px;
    padding: 1rem;
    text-align: center;
    font-family: "Mulish", sans-serif;
    font-size: 1rem;
    font-weight: 800;
    margin: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .overview-card h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .overview-card p {
    font-size: 1.5rem;
    font-weight: 800;
  }
  
  /* Add Provider Modal Styling */
  .ant-modal-content {
    border-radius: 12px;
    padding: 1rem;
  }
  
  .ant-modal-header {
    border-bottom: 2px solid #A68200; /* Yellow accent */
  }
  
  .ant-modal-title {
    font-family: "Mulish", sans-serif;
    color: #375945;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  /* Form Inputs */
  .ant-form-item-label > label {
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    color: #375945;
  }
  
  .ant-input {
    border-radius: 6px;
  }
  
  /* BILLING STYLES */

  .admin-clients-section p {
    font-size: 1rem;
    font-weight: 800;
    color: #375945;
    font-family: "Mulish", sans-serif;
  }

  .payment-section {
    padding: 2rem;
  }

  .invoice-history-table {
    padding: 2rem;
  }
  
  /* Make the Invoice History section white */
.admin-clients-section.white-section {
  background-color: white; /* White background */
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
}

/* Table header and rows */
.my-providers-table .ant-table-thead > tr > th {
  background-color: #f5f5f5; /* Light gray for header */
  color: #375945; /* Dark green for text */
  font-weight: bold;
}

.my-providers-table .ant-table-tbody > tr > td {
  background-color: white; /* White for table rows */
  color: #375945; /* Dark green for text */
}

/* Table row hover effect */
.my-providers-table .ant-table-tbody > tr:hover > td {
  background-color: #f9f9f9; /* Light hover effect */
}

/* Specific Invoice History Table Styling */
.admin-clients-section.white-section .ant-table {
  background-color: white !important; /* Ensure table background is white */
  border-radius: 12px;
}

.admin-clients-section.white-section .ant-table-thead > tr > th {
  background-color: white !important; /* Table header background */
  color: #375945; /* Text color */
  font-weight: bold;
}

.admin-clients-section.white-section .ant-table-tbody > tr > td {
  background-color: white !important; /* Table row background */
  color: #375945; /* Text color */
}

.admin-clients-section.white-section .ant-table-tbody > tr:hover > td {
  background-color: #f9f9f9 !important; /* Light gray hover effect */
}

/* Pagination Styling */
.admin-clients-section.white-section .ant-pagination {
  background-color: white;
}

