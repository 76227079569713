  .new-home {
    font-family: 'Mulish';
    overflow-x: hidden;

  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .logo img {
    height: 40px; /* Adjust logo height */
  }
  
  .navigation li {
    font-size: 1rem;
    font-weight: 500;
    margin-right: 1.45rem;
  }
  
  .navigation {
    display: flex;
    margin-right: 2rem;
  }
  
  .navigation ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 2rem;
  }
  
  .navigation a {
    text-decoration: none;
    color: #375945;
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  .navigation a:hover {
    color: black;
  }
  
  /* Hamburger Menu Styling */
  .menu-toggle {
    display: none;
    flex-direction: column;
    gap: 5px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .menu-toggle span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #375945;
    border-radius: 3px;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  /* Rotate spans when menu is open */
  .menu-toggle.open span:nth-child(1) {
    transform: rotate(45deg) translate(0.35rem, 0.35rem);
  }
  
  .menu-toggle.open span:nth-child(2) {
    opacity: 0;
  }
  
  .menu-toggle.open span:nth-child(3) {
    transform: rotate(-45deg) translate(0.35rem, -0.35rem);
  }

  .bold-header-title {
    font-weight: 900;
  }
  
  .hero {
    text-align: center;
    padding: 2rem 0rem;
    background-color: 'white';
  }

  .hero-text {
    margin-left: 15%;
    margin-right: 15%;
  }
  
  .hero-text h1 {
    font-size: 4rem;
    margin-bottom: 1rem;
    color: #375945;
    font-weight: 900;
  }
  
  .hero-text p {
    font-size: 1.2rem;
    color: #8A958F;
    font-weight: 500;
  }
  
  .hero-images {
    display: flex; /* Aligns child elements in a row */
    justify-content: center; /* Centers the images horizontally */
    align-items: center; /* Aligns the images vertically */
    gap: 2rem; /* Adds space between the images */
    margin-top: 2rem;
  }
  
  .hero-images img {
    height: 400px; /* Maintain image aspect ratio */
    width: auto; /* Adjust this value to match your design */
  }  

  .mobile-image {
    padding: 2rem 2rem;
    background-color: #FFEBA3;
    border-radius: 30px;
    box-shadow: #8A958F 0px 4px 4px;
  }

  .web-image {
    padding: 2rem 2rem;
    background-color: #F8F8F8;
    border-radius: 30px;
    box-shadow: #8A958F 0px 4px 4px;
  }

  .why-yosa-text {
    margin: 0rem 4rem 2rem 4rem;
  }

  .why-yosa h2 {
    color: #375945;
    font-weight: 900;
    font-size: 2.3rem;
  }

  .why-yosa p {
    color: #375945;
    font-weight: 500;
    font-size: 1.4rem;
  }

  .link-to-evidence {
    color: #375945;
  }
  
  .how-it-works {
    padding: 2rem 1rem;
    text-align: center;
  }

  .home-title h2 {
    color: #375945;
    font-weight: 900;
  }

  .sub-title h2 {
    color: #8A958F;
    font-weight: 500;
    font-size: 1.2rem;
  }
  
  .steps {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding-top: 1rem;
  }
  
  .step {
    flex: 1;
    max-width: 300px;
    padding: 2rem;
    background-color: white;
    border-radius: 20px;
    box-shadow: #8A958F 0px 4px 8px;
  }

  .step-one {
    background-color: #9BBFAA;
    color: #375945;
    font-weight: 900;
    font-size: 1.5rem;
    padding: 10px 0px 10px 0px;
    margin: 0 190px 20px 0;
    border-radius: 10px;
  }

  .link-to-product {
    color: #375945;
  }

  .step-two {
    background-color: #FFEBA3;
    color: #BA9100;
    font-weight: 900;
    font-size: 1.5rem;
    padding: 10px 0px 10px 0px;
    margin: 0 190px 20px 0;
    border-radius: 10px;
  }

  .step-three {
    background-color: #5E5E5E;
    color: #C3D7CB;
    font-weight: 900;
    font-size: 1.5rem;
    padding: 10px 0px 10px 0px;
    margin: 0 190px 20px 0;
    border-radius: 10px;
  }

  .steps p {
    color: black;
    font-weight: 600;
    font-size: 1.15rem;
  }
  
  .client-provider {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin: 4rem 2rem;
  }
  
  .client-app, .provider-portal {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
  
  .client-app {
    flex-direction: row; /* Client App image on the right */
  }
  
  .provider-portal {
    flex-direction: row; /* Provider Portal image on the left */
  }
  
  .client-app-text, .provider-portal-text {
    max-width: 50%;
  }

  .client-app-text {
    background-color: #59846A;
    padding: 2rem;
    border-radius: 20px;
  }
  
  .client-app-text h2 {
    color: #FFEBA3;
    font-size: 1.8rem;
    font-weight: 900;
    margin-bottom: 1rem;
  }

  .client-app-text p {
    color: white;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
  }

  .provider-portal-text {
    background-color: #5E5E5E;
    padding: 2rem;
    border-radius: 20px;
  }

  .provider-portal-text h2 {
    color: #C3D7CB;
    font-size: 1.8rem;
    font-weight: 900;
    margin-bottom: 1rem;
  }

  .provider-portal-text p {
    color: white;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
  }
  
  .client-app-image img, .provider-portal-image img {
    max-height: 300px;
    height: auto;
  }

  .security {
    text-align: center;
    margin: 0rem auto;
    max-width: 1200px; /* Constrain width for readability */
    padding: 2rem;
    align-content: center;
  }
  
  .security-header h1 {
    color: #375945;
    padding: 2rem;
    font-size: 2.5rem;
    font-weight: 900;
    max-width: 1200px; /* Optional: Adjust width to match the desired design */
    line-height: 1.4;
  }
  
  .security-content {
    display: flex;
    flex-direction: row;
    align-items: center; /* Vertically aligns text and image */
    justify-content: center; /* Horizontally centers the content */
    gap: 2rem; /* Adds space between text and image */
    text-align: left; /* Align text within the text block */
    margin: 0 auto; /* Ensures the entire content is centered */
    max-width: 1200px; /* Constrains the section width */
    padding: 0rem 2rem;
    border-radius: 30px;
  }  
  
  .security-text {
    flex: 1;
    text-align: left;
  }
  
  .security-text h2 {
    color: #375945;
    font-size: 1.8rem;
    font-weight: 900;
    margin-bottom: 1rem;
  }
  
  .security-text p {
    color: #375945;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    width: 80%;
  }
  
  .security-text ul {
    list-style-type: none; /* Remove default bullet points */
    padding: 0;
    margin: 0;
  }
  
  .security-text ul li {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #375945;
    margin-bottom: 0.5rem;
  }
  
  .security-text ul li::before {
    content: "•"; /* Custom bullet point */
    color: #375945;
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
  
  .security-image img {
    max-height: 330px;
    width: auto;
    padding-right: 2rem;
  }  
  
  .toolbox-title {
    color: #375945;
    font-size: 2rem;
    font-weight: 900;
    text-align: center;
  }

  .learn-more a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #A68200;
    text-decoration: none;
  }

  .learn-more a:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5E5E5E;
    text-decoration: none;
  }

  .animation-container {
    width: 100%;
    height: 30vh; /* Adjust the height */
    overflow: hidden;
  }
  
  .nature-animation-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .nature-animation {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top; /* Crops from the bottom */
  }

  .vision {
    padding-top: 7rem;
    padding-bottom: 9rem;
    margin: -7rem auto;
    max-width: 1200px;
  }
  
  .vision-container {
    flex: 1;
    text-align: left;
  }
  
  .vision-title {
    color: #7a5b3c;
    font-size: 2.5rem;
    font-weight: 900;
    margin-bottom: 1rem;
  }
  
  .vision-description {
    color: #a06e12;
    font-size: 1.5rem;
    line-height: 1.6;
  }
  
  .vision-description strong {
    font-weight: bold;
  }

  .newsletter {
    text-align: center;
    padding: 2rem;
    margin-bottom: 2rem;
    color: #375945;
  }
  
  .newsletter-form {
    margin-top: 1rem;
  }
  
  .newsletter-form input {
    padding: 0.5rem 0 0.5rem 1.25rem;
    margin-right: 0.5rem;
    width: 60%;
    max-width: 300px;
    border-radius: 20px;
    border: 2px solid #375945;
    outline: none;
  }
  
  .newsletter-form button {
    padding: 0.5rem 1rem;
    border-radius: 20px;
    border: 2px solid #375945;
  }

  /* Mobile View: Screens smaller than 768px */
@media (max-width: 768px) {
  .header {
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 2.5rem 1rem;
  }

  .menu-toggle {
    display: flex;
  }

  .navigation {
    position: absolute;
    top: 70px;
    right: 0;
    background-color: white;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transform: translateY(-150%);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
  }
  
  .navigation.open {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  .navigation ul {
    flex-direction: column;
    align-items: center;
  }

  .navigation a {
    font-size: 1rem;
  }

  .hero {
    padding: 1rem 0rem;
    text-align: center;
  }

  .hero-text h1 {
    font-size: 2.5rem;
    margin-top: 1rem;
  }

  .hero-text p {
    font-size: 1rem;
  }

  .why-yosa {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
    justify-content: center; /* Center content vertically (if necessary) */
    text-align: center; /* Center text inside the section */
    padding: 1.5rem 0.5rem; /* Add padding around the section */
  }

  .why-yosa-text {
    max-width: 700px; /* Constrain the width for readability */
  }

  .steps {
    flex-direction: column;
    gap: 2rem;
    padding: 1.5rem;
  }

  .step {
    max-width: 100%;
    margin: 0 auto;
  }

  .client-provider {
    flex-direction: column;
    gap: 2rem;
  }

  .client-app,
  .provider-portal {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .client-app-text,
  .provider-portal-text {
    max-width: 100%;
  }

  .learn-more {
    margin: 0rem 2.25rem;
    display: flex;
    text-align: center;
  }

  .security-header h1 {
    font-size: 2rem;
  }

  .security {
    margin: 2rem -1rem;
  }

  .security-content {
    flex-direction: column;
    text-align: center;
  }

  .security-text {
    margin-bottom: 1rem;
  }

  .security-text h2 {
    display: flex;
    text-align: center;
  }

  .security-text p {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .security-image img {
    padding: 0;
  }

  .vision {
    margin: 2rem 1rem;
    padding: 2rem;
  }

  .newsletter-form input {
    width: 100%;
    max-width: none;
    margin-bottom: 0.5rem;
  }

  .newsletter-form button {
    width: 100%;
  }

  .footer {
    text-align: center;
    padding: 1rem;
  }

  .footer-content ul {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}

@media (max-width: 768px) {
  .faq-header h1 {
    font-size: 1.5rem;
    max-width: 100%;
  }

  .faq-header h2 {
    font-size: 1rem;
    max-width: 90%;
  }
}

