.hero-research {
    text-align: center;
    padding: 2rem 1rem;
    background-color: white; /* Removed quotes for valid CSS */
    display: flex;
    flex-direction: column; /* Ensures proper stacking of text and image */
    align-items: center; /* Centers content horizontally */
  }
  
  .evidence-header {
    flex: 1;
    color: #375945;
    margin: 0 2rem 1rem 2rem;
    text-align: center; /* Centers the text horizontally */
  }
  
  .hero-research img {
    width: 100%; /* Ensure it spans the container width */
    height: auto; /* Maintain proportions */
    object-fit: cover; /* Crop the image to fill the container while maintaining proportions */
    max-height: 400px; /* Optional: Constrain max height */
    border-radius: 10px; /* Optional: Add rounded corners */
  }

  .evidence-subheader, .hero-research h2 {
    font-weight: 400;    
    font-size: 1.5rem;
    color: #8A958F;
    margin: 0.5rem 0;
  }

  .features-right img, .limitations-image img, .solution-image img {
    width: 60vh; /* Ensure it spans the container width */
    height: auto; /* Maintain proportions */
  }
  
 .limitations-section, .solution-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 4rem;
    gap: 6rem;
  }
  
  .limitations-text, .solution-text {
    flex: 1;
    font-family: 'Mulish';
    color: #375945;
  }
  
  .limitations-text h2, .solution-text h2 {
    font-size: 2rem;
    font-weight: 900;
    margin-bottom: 1rem;
  }
  
  .limitations-text p, .solution-text p {
    font-size: 1rem;
    line-height: 1.6;
    color: #6b8068;
  }
  
  .limitations-text ul, .solution-text ul {
    list-style-type: disc;
    padding-left: 1.5rem;
    margin: 1rem 0;
  }
  
  .limitations-text ul li, .solution-text ul li {
    font-size: 1rem;
    line-height: 1.6;
    color: #375945;
    margin-bottom: 0.5rem;
  }
  
  .limitations-image img, .solution-image img {
    max-width: 100%;
    height: auto;
  }

  .bridging-text {
    font-size: 1.3rem !important;
    margin-top: 1rem;
  }  

  .cbt-accordion {
    font-family: 'Mulish';
    margin-bottom: 1rem;
    margin-top: 2rem;
    width: 100%; /* Ensure accordion takes full width of the container */
    box-sizing: border-box; /* Include padding in width */
  }

  .cbt-accordion p {
    font-family: 'Mulish';
    color: #375945;
    margin: 0.5rem 0;
  }

  .cbt-accordionpanel {
    font-family: 'Mulish';
  }

  .beckquote {
    font-size: 1.9rem !important; /* Adjust size as needed */
    text-align: center; /* Center-align the text */
    margin: 2rem 0; /* Add some spacing */
    line-height: 1.8; /* Adjust line height for readability */
  }
  
  .beck-bridging-text {
    font-size: 1.2rem; /* Slightly smaller for attribution */
    text-align: center;
    margin-top: 0.5rem;
    font-style: italic; /* Optional: Italicize the attribution */
  }
  
  .bold-text {
    font-weight: bold; /* Makes only the second sentence bold */
  }

  .homework-modalities {
    margin: 2rem 0;
    padding: 4rem;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .homework-modalities h2 {
    text-align: center;
    font-size: 2rem;
    color: #375945;
    margin-bottom: 1.5rem;
  }
  
  .ant-tabs {
    font-family: 'Mulish', sans-serif;
  }
  
  .ant-tabs-tab {
    font-size: 1.2rem;
    font-weight: 500;
    color: #375945;
  }
  
  .ant-tabs-tab-active {
    font-weight: bold;
    color: #2a5937 !important;
  }
  
  .ant-tabs-ink-bar {
    background-color: #375945 !important; /* Overrides the default blue underline */
  }

  .ant-dropdown-menu {
    background-color: #f9f9f9; /* Dropdown background */
    border-radius: 5px;
  }
  
  .ant-dropdown-menu-item a {
    color: #375945 !important; /* Text color */
    font-size: 1rem;
  }
  
  .ant-dropdown-menu-item:hover {
    background-color: #e6f7e6; /* Highlight on hover */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .limitations-section, .solution-section {
      flex-direction: column; /* Stack items on smaller screens */
      text-align: center;
    }
  
    .limitations-image, .solution-image {
      margin-top: 2rem;
    }

    .solution-image img {
      margin-top: -2rem;
      margin-bottom: 1rem;
    }
  }
  