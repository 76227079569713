.assignment-toolboxX {
    display: flex;
    height:100%;
  }

  .toolbox-content-outer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

.toolbox-content {
    background-color: white;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: 'white';
    padding: 40px;
    overflow-y: auto;
    padding-top: 0px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  
  .assignments-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .assignment-toolbox-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .assignment-toolbox-card h3 {
    margin-bottom: 8px;
    font-size: 18px;
  }
  
  .assignment-toolbox-card p {
    color: #666;
  }

  .assignment-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .assignment-text {
    flex: 1;
    padding-right: 20px;
    font-family: 'Mulish';
  }
  
  .assignment-text h3 {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 700;
    color: black;
  }
  
  .assignment-text p {
    margin-bottom: 15px;
    color: #323232;
  }
  
  .assignment-button {
    background-color: #59846A; /* Green button */
    border: none;
  }
  
  .assignment-toolbox-image {
    width: 100px; /* Fixed width */
    height: 100px; /* Fixed height */
    object-fit: contain; /* Ensures the entire image fits within the box */
    border-radius: 8px; /* Optional: keeps rounded corners */
  }
  
  /* Ensure preview images are responsive and well-styled */
  img {
    max-width: 100%;
    display: block;
    margin: 10px auto;
    border-radius: 8px;
  }
  
  