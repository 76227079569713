.community-container {
    display: flex;
    background-color: white; /* Ensure it overrides any inherited styles */
    color: #375945; /* Optional: Set text color */
    font-family: 'Mulish';
    height: 100vh; /* Ensure full height for the container */
    overflow: hidden; /* Prevent scrolling the entire container */
}

.filter-section {
    display: flex; /* Enables Flexbox for the sidebar */
    flex-direction: column; /* Stacks items vertically */
    justify-content: space-between; /* Pushes the footer to the bottom */
    width: 20%;
    height: 100%; /* Full height for independent scrolling */
    padding: 1rem;
    padding-top: 0px;
    background-color: #375945 !important;
    border-right: 1px solid #ddd;
    border: none !important;
    box-shadow: none !important;
    overflow-y: auto; /* Allow scrolling for the content above the button */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    position: fixed; /* Fix position for sidebar */
    top: 0;
    left: 0;
    z-index: 5; /* Ensure it appears below the header */
    box-sizing: border-box; /* Ensure padding is included in the width */
}

.saved-assignments-button, .share-assignments-button {
    display: block;
    width: 100%;
    padding: 0.5rem;
    background-color: #375945;
    color: white;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    margin-top: auto;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.back-button {
    display: flex; /* Align children (icon and text) in a row */
    align-items: center; /* Vertically center icon and text */
    justify-content: center; /* Center content horizontally */
    gap: 8px; /* Add spacing between icon and text */
    color: white; /* Match the text color */
    background-color: #375945;
    border: none;
    border-radius: 30px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 0.5rem 1rem; /* Add padding for better click area */
    margin-bottom: 1rem; /* Add spacing between buttons */
}

.back-button:hover {
    background-color: #508a63;
    color: white;
}

.share-with-community-button {
  display: flex; /* Align content in a row */
  align-items: center; /* Vertically center */
  justify-content: center; /* Horizontally center */
  text-align: center; /* Ensure text stays centered */
  width: 100%; /* Ensure button spans correctly */
  color: black;
  background-color: #ffdd64;
  border: none;
  border-radius: 30px;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  white-space: normal; /* Allows wrapping */
}

.share-with-community-button span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.share-with-community-button:hover {
  background-color: #1C3727;
  color: white;
}

.filter-section h2 {
    font-size: 1.4rem;
    color: white;
    font-weight: 800;
    margin-top: 10px;
}

.filter-section h3 {
    margin-top: 1rem;
    font-size: 1.2rem;
    color: #59846A;
}

.community-assignments-container {
    flex: 1;
    margin-left: 20%; /* Offset by sidebar width */
    overflow-y: auto; /* Allow the assignments list to scroll */
    height: 100%; /* Full height */
    position: relative;
    padding-bottom: 1.5rem;
}

.assignments-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #C3D7CB; /* Add background color */
    position: sticky; /* Stick to the top when scrolling */
    top: 0;
    z-index: 10; /* Ensure it appears above the list and sidebar */
    padding: 1.5rem 0;
    color: white; /* Text color for better contrast */
}

.sort-dropdown select {
    margin-left: 0.5rem;
    padding: 0.2rem 0.5rem;
    border-radius: 20px;
    border: none;
}

.assignments-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    padding-top: 1rem;
    padding: 0 20px;
}

.accordion {
    border-bottom: 1px solid #ddd;
    margin-bottom: 1rem;
    width: 100%; /* Ensure accordion takes full width of the container */
    box-sizing: border-box; /* Include padding in width */
}

.accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem 0;
    width: 100%; /* Prevent the header from exceeding container width */
    box-sizing: border-box;
}

.accordion-header h3 {
    font-size: 1.1rem;
    color: white;
    font-weight: 800;
    margin: 0;
    white-space: nowrap; /* Prevent wrapping */
    overflow: hidden; /* Hide overflowing content */
    text-overflow: ellipsis; /* Add ellipsis for long text */
}

.accordion-header span {
  color: white;
}

.accordion-content {
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%; /* Prevent the content from exceeding container width */
    box-sizing: border-box;
}

.accordion-content label {
    font-size: 0.9rem;
    color: white;
    white-space: normal; /* Allow the text to wrap to the next line */
    overflow: visible; /* Ensure no truncation occurs */
    text-overflow: unset; /* Remove ellipses */
    text-align: left;
    line-height: 1.6; /* Add spacing between lines for better readability */
    margin-bottom: 0.4rem;
    display: block; /* Make each label take full width for better wrapping */
}

.checkbox-label {
  display: flex;
  align-items: center;
}

.checkbox-label input {
  margin-right: 8px; /* Adjust spacing */
}

.community-info-text {
  margin-top: 15px;
  margin-left: 20px;
  color: black;
}

.pagination-controls {
  display: flex;
  justify-content: left;
  color: black;
  align-items: center;
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  gap: 10px;
}

.pagination-button {
  padding: 3px 8px;
  background-color: #1C3727;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  width: 100px;
  transition: all 0.3s ease;
}

.pagination-button:disabled {
  background-color: #A0A0A0;
  cursor: not-allowed;
}

@media (max-width: 768px) {
    .community-container {
      flex-direction: column; /* Stack filter and assignments vertically */
      height: auto; /* Allow the height to adjust naturally */
      overflow: visible; /* Enable scrolling on the entire container */
    }
  
    .filter-section {
      position: relative; /* Move sidebar into the flow */
      width: 100%; /* Take full width of the screen */
      height: auto; /* Let height adjust based on content */
      padding: 1rem;
      border-right: none; /* Remove the border for cleaner design */
      border-bottom: 1px solid #ddd; /* Add a border at the bottom */
      overflow: visible; /* Allow the entire filter section to expand */
    }
  
    .community-assignments-container {
      margin-left: 0; /* Remove the sidebar offset */
    }
  
    .sort-dropdown {
      width: 100%; /* Take full width */
    }
  
    .assignments-list {
      flex-direction: column; /* Stack assignments vertically */
      gap: 1rem; /* Add spacing between cards */
    }
  
    .accordion-header {
      font-size: 1rem; /* Adjust header font size */
    }
  
    .accordion-content label {
      font-size: 0.9rem; /* Adjust label font size */
    }
  
    .back-button {
      font-size: 0.8rem; /* Adjust button text size */
      padding: 0.5rem; /* Reduce padding for smaller screens */
    }
  
    .saved-assignments-button,
    .share-assignments-button {
      font-size: 0.9rem; /* Reduce font size */
      padding: 0.5rem; /* Adjust padding */
    }
  }
  