.signuplogo {
  width: 30%;
  margin-bottom: 30px;
  margin-left: auto; /* Center horizontally */
  margin-right: auto; /* Center horizontally */
  display: block; /* Ensure it's treated as a block element */
}

.forgot-password {
    border: 0;
}

.submit-button {
    background-color: #59846A;
    margin-top: 10px;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.policyheader {
    background-color: #264834;
    
}

body {
    background-color: #264834;
    font-family: 'Mulish', sans-serif;
    color: white;
    
}

main {
    background-color: #fff;
    color: #375945;
    padding: 20px;
    flex: 1;
}

.last-updated {
    color: #375945;
    font-size: 2em;
    text-align: left;
    margin-top: 10px;
    margin-left: 10%;
    font-weight: 650;
}

.policy-header{
    color: #375945;
    font-size: 2em; /* Increase the font size */
    text-align: left;
    margin-top: 10px;
    font-weight: 650;
    
}

.policy-header2{
    color: #375945;
    font-size: 1.5em;
    text-align: left;
    margin-top: 10px;
    font-weight: 650;
    
}

.privacy-title {
    color: #375945;
    text-align: center;
    font-weight: 700;
    padding-top: 1.2rem;
    padding-bottom: 1.8rem;
}

.content-container {
    margin-left: 10%;
    margin-right: 10%;
}

.footer {
    background-color: #FFEBA3;
    padding: 20px 0;
    text-align: center;
    border-top: 1px solid #e7e7e7;
    position: relative;
    bottom: 0;
    width: 100%;
    font-family: 'Mulish', sans-serif;
    font-weight: 100;
  }

  .lighter-bold {
    font-weight: 650;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
  }
  
  .footer-content p {
    margin: 0 0 10px 0;
    color: #333;
    
    
  }
  
  .footer-bottom {
    background-color: #FFEBA3;
    padding: 20px 0;
    text-align: center;
    
    position: relative;
    bottom: 0;
    width: 100%;
    font-family: 'Mulish', sans-serif;
    font-weight: 800;
    gap: 10px;
  }
  
  .footer-bottom .email-link {
    color: inherit;
    text-decoration: none;
    margin-right: 30px;
    
  }
  
  .footer-bottom .linkedin-icon {
    width: 24px;
    height: 24px;
    gap: 10px;
  }
  
  .footer-content nav ul {
    list-style: none;
    padding: 0;
    margin: 10px 0 0;
    display: flex;
    justify-content: center;
    
  }
  
  .footer-content nav ul li {
    margin: 0 15px;
    
  }
  
  .footer-content nav ul li a {
    color: #333;
    text-decoration: none;
  }
  
  .footer-content nav ul li a:hover {
    text-decoration: underline;
  }

  .footer-enter-email {
    color: #375945;
    font-size: 2rem;
    font-family: 'Mulish' sans-serif ;
    font-weight: 800;
  }
  
  #waitlist-email-form {
    margin-top: -15px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  #waitlist-email-form label {
    margin-bottom: 0.75rem; /* Adjust spacing between label and input */
  }

  .waitlist-label {
    color: #333; /* Darker color */
    font-weight: 800; 
    font-family: 'Mulish', sans-serif
  }
    
  #waitlist-email-form input[type=email] {
    padding: 10px;
    margin-bottom: 1rem;
    border: 1px solid #323232;
    border-radius: 10px;
    flex-grow: 1;
    margin-right: 1rem;
    margin-left: 1rem;
    width: 70%;
  }
    
    #waitlist-email-form button {
      padding: 10px;
      background-color: #59846A;
      border: 1px solid #323232;
      color: white;
      cursor: pointer;
      border-radius: 10px;
      margin-bottom: 16px;
    }
    
    #waitlist-email-form button:hover {
      background-color: #375945;
      transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    }


    @media (max-width: 768px) {
      .signup-left {
          display: none; /* Hide the image container on small screens */
      }
  
      .signup-right {
          width: 100%;
          padding: 0 20px;
      }
  
      .signuplogo {
          margin: 0 auto; /* Center the logo */
          display: block;
          height: 125px;
          width: auto;
          padding-bottom: 25px;
      }
  }
  