.wholescreen {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin: 0;
}

/* Override Ant Design Menu background */
.ant-menu {
  background-color: #375945 !important; /* Ensure the entire menu is the same green */
  border: none; /* Remove borders */
}

/* Ensure that each menu item also has the same background */
.ant-menu-item {
  background-color: #375945 !important;
  color: white; /* Ensure the text color is readable */
}

/* Style the selected menu item */
.ant-menu-item-selected {
  background-color: #508a63 !important; /* Different shade of green when selected */
  color: black !important;
}

/* Menu item hover state */
.ant-menu-item:hover {
  background-color: #508a63 !important; /* Same as selected item hover */
  color: black;
}

/* Optional: If you have submenu items or dropdowns */
.ant-menu-submenu-title {
  background-color: #375945 !important;
  color: black;
}

.ant-menu-submenu-title:hover {
  background-color: #508a63 !important;
  color: black;
}


.header {
  padding: 0;
  border-bottom: 2px transparent; /* Change this to the desired border color */
  height: 3.3em;
  color: #375945;
}

.expand-button {
  background: '#AC8200';
  color: 'white';
  border: '0px solid #ccc';
  border-radius: '7px';
  cursor: 'pointer';
  padding: '5px 10px';
  font-size: '15px';
  margin-left: '5px';
}

.scrollable-content {
  height: 100vh;
  background-color: transparent;
  overflow-x: hidden;
  flex: 1;
  flex-direction: row;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}

.dashboard-content {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  margin-right: 0;
  padding: 0;
}

.top-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow-x: hidden;
}

.balancingguy img {
  width: 100%;
  height: auto;
  max-width: 300px;
  background-color: transparent;
}

.worksheet-section,
.clients-section,
.analytics-section {
  background-color: transparent;
  padding: 25px;
  border-radius: 15px;
  flex: 1;
  margin: 0 2.4rem;
  width: 80%;
  color: black;
  min-width: 400px;
}

.clients-section {
  border: 3px solid #375945;
  margin-top: 10px;
}

.worksheet-section {
  border: 3px solid #A68200;
  margin-top: 20px;
}

.analytics-section {
  border: 3px solid #C3D7CB;
  margin-bottom: 25px;
  margin-top: 20px;
}

.worksheets-table {
  margin-left: 22px;
}

.dashboard-title {
  font-size: 1.8em;
  font-weight: 700;
  margin-left: 40px;
  margin-top: 30px;
  margin-bottom: 10px;
  color: black;
}

.tablename {
  font-size: 1.4em;
  font-weight: 700;
  margin-bottom: 25px;
}

.greentguy {
  max-height: 32vh;
}

.nothingtext {
  font-family: 'Mulish';
  color: #1C3727;
  text-align: left;
  padding-left: 20px;
}

.nothingtext2 {
  font-family: 'Mulish';
  color: #1C3727;
  text-align: left;
}

.instructions {
  padding-top: 5px;
}

.footer {
  background-color: transparent;
  padding: 20px 0;
  text-align: center;
  border-top: 1px solid #e7e7e7;
  margin-top: auto;
  position: relative;
  bottom: 0;
  width: 100%;
  font-family: 'Mulish', sans-serif;
  font-weight: 100;
  margin: 0;
}

/* Custom table styles */
.ant-table-thead > tr > th {
  background-color: transparent !important;
  color: black !important;
  border: none !important;
}

.clients-section .ant-table-thead > tr > th {
  background-color: transparent !important;
  color: black !important;
  border: none !important;
}

.ant-table-tbody > tr:hover > td {
  background-color: transparent !important;
  color: black;
}

.clients-section .ant-table-tbody > tr:hover > td {
  background-color: transparent !important;
  color: black;
}

.ant-table-tbody > tr > td {
  background-color: transparent;
  color: black;
  border: none !important;
  font-size: 1.2em;
}

.clients-section .ant-table-tbody > tr > td {
  background-color: transparent;
  color: black;
  border: none !important;
}

.worksheet-section .ant-table-tbody > tr > td a {
  color: transparent;
}

.clients-section .ant-table-tbody > tr > td a {
  color: #A68200;
}

.ant-table-thead > tr > th {
  font-weight: bold;
}

.ant-table-tbody > tr > td {
  font-weight: normal;
}


/* Target the selection column header cell to remove or change the white box color */
.clients-section .ant-table-selection-column {
  background-color: transparent !important; /* Change to desired color */
  border: none !important;
}

.clients-section .ant-table-cell {
  background-color: transparent !important; /* Ensure consistent background color */
}

/* Custom styles for the selected menu item */
.ant-menu-item-selected {
  background-color: transparent !important; /* Change to your desired color */
  color: #ffffff !important; /* Change the text color if needed */
}


@media (max-width: 1200px) {
  .dashboard-content {
    flex-direction: column;
    min-height: 100vh;
  }

  .worksheet-section,
  .clients-section {
    margin-bottom: 20px;
  }

  .balancingguy {
    visibility: hidden;
  }

  .top-section {
    flex-direction: column;
  }

}

.custom-tabs-container {
  display: flex;
  gap: 20px; /* Spacing between the tabs */
  height: 20px;
}

.custom-tab {
  background-color: transparent;
  padding: 0 20px;
  font-size: 16px;
  font-weight: bold;
  color: #A7A7A7; /* Default tab background */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  line-height: 0;
}

.custom-tab:hover {
  color: black; /* Lighter gray on hover */
}

.custom-tab.active {
  color: black; /* Active tab text color */
}

.arrow-icon {
  transition: transform 0.3s ease-in-out;
  font-size: 15px;
}

/* Rotate the arrow down */
.rotate-down {
  transform: rotate(90deg);
}

/* Rotate the arrow to the right */
.rotate-right {
  transform: rotate(0deg);
}

.toggle-icon :hover {
  cursor: pointer;
}

/* Default button styles */
.custom-client-button {
  border: none;
  border-radius: 35px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-right: 10px;
  margin-left: 10px;
}

.custom-library-button {
  background-color: #59846a;
  color: white;
  border: none;
  border-radius: 35px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-right: 10px;
  margin-left: 10px;
}

.custom-library-button:hover {
  background-color: #323232; /* Hover color */
  color: white !important;
}

.check-registration-status-button {
  background-color: #9bbfaa;
  color: white;
  border: none;
  border-radius: 35px;
  height: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  padding: 13px;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: 40px;
}

.check-registration-status-button:hover {
  background-color: #375945;
  color: white;
}

/* Styles for Registered clients */
.custom-client-button.registered {
  background-color: #a68200;
  color: white;
}

.custom-client-button.registered:hover {
  background-color: #323232; /* Hover color */
  color: white !important;
}

/* Styles for Not Registered clients */
.custom-client-button.not-registered {
  background-color: #eeeeee; /* Grey background */
  color: #a7a7a7; /* Grey text */
  cursor: not-allowed; /* Disabled cursor */
}

/* Disabled button styling */
.custom-client-button:disabled {
  background-color: #eeeeee;
  color: #a7a7a7;
  cursor: not-allowed;
}