.assignment-card {
    display: flex;
    flex-direction: column;
    width: 35vw;
    height: 340px;
    background: #f8f8f8;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    gap: 1rem;
    position: relative;
    overflow: scroll;
    scrollbar-width: none;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align title and buttons to the top */
    gap: 1rem;
}

.header-left {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0; /* Ensure ellipsis works */
}

.header-left h3 {
    font-size: 1.5rem;
    color: #375945;
    font-weight: 800;
    margin: 0;
    white-space: nowrap; /* Prevent line breaks */
    overflow: hidden; /* Hide overflowed text */
    text-overflow: ellipsis; /* Add ellipsis for overflowed text */
}

.tags {
  display: flex;
  flex-wrap: nowrap; /* Prevents wrapping to a new row */
  overflow-x: auto; /* Enables horizontal scrolling */
  scrollbar-width: thin; /* Makes the scrollbar smaller */
  scrollbar-color: #888 #f5f5f5; /* Custom scrollbar color */
  gap: 5px; /* Adds some spacing between tags */
  padding-bottom: 5px; /* Ensures scrollbar does not overlap text */
}

/* Webkit scrollbar styles (for Chrome, Safari) */
.tags::-webkit-scrollbar {
  height: 6px;
}

.tags::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.tags::-webkit-scrollbar-track {
  background: #f5f5f5;
}

/* Optional: Ensure each tag has appropriate styling */
.tag {
  white-space: nowrap; /* Prevents text wrapping inside tags */
  padding: 5px 10px;
  border-radius: 30px;
  background-color: #e0e0e0;
  font-size: 14px;
}
  
  .tag-modality {
    background-color: #59846a; /* Greenish for modality */
    color: #FFEBA3;
  }
  
  .tag-demographic {
    background-color: #FFEBA3; /* Yellowish for demographic */
    color: #A68200;
  }
  
  .tag-topic {
    background-color: #5B5B5B; /* Dark green for topic */
    color: #C3D7CB;
  }
  

.header-buttons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 10px 0;
}

.view-button {
    background-color: #375945;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    height: fit-content; /* Ensure it aligns with the title */
}

.view-button:hover {
    background-color: #2c4737;
}

.view-button-disabled,
.view-button-disabled:hover {
  background-color: #c3d7cb;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 0.5rem 1rem;
  cursor: not-allowed !important;
  height: fit-content; /* Ensure it aligns with the title */
}

.delete-button {
  background-color: #FFDD64;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  height: fit-content; /* Ensure it aligns with the title */
}

.delete-button:hover {
  background-color: #FFD232;
}

.preview-button {
  background-color: #0077cc;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  height: fit-content; /* Ensure it aligns with the title */
}

.preview-button:hover {
  background-color: #005fa3;
}

.book-button {
    background: none; /* Remove background */
    border: none; /* Remove border */
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.save-icon {
    color: #375945;
    font-size: 2rem;
}

.card-content {
    display: flex;
    align-items: flex-start; /* Align image and description to the top */
    justify-content: left;
    gap: 1rem;
    flex: 1;
}

.assignment-image {
    height: auto; /* Allow image to adjust height naturally */
    max-height: 320px; /* Ensure it doesn't overflow */
    width: auto;
    max-width: 200px; /* Prevent image from overflowing */
}

.assignment-description {
    flex: 1;
    font-size: 0.9rem;
    color: #333;
    line-height: 1.5;
    margin: 0; /* Remove any extra margin */
    padding: 0;
    text-align: left;
}

.modal-background-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dimmed background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 2rem; /* Add spacing to prevent modal from hitting edges */
}

.modal-dialog-content {
    background: white; /* White modal */
    padding: 2rem;
    border-radius: 12px; /* Modern rounded edges */
    max-width: 80vw; /* Use percentage for better responsiveness */
    max-height: 90vh; /* Use percentage for better responsiveness */
    text-align: center;
    position: relative;
    overflow: auto; /* Allow scrolling for overflowed content */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); /* Add a subtle shadow for depth */
}

.modal-dialog-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
}

.modal-dialog-header {
    display: flex;
    justify-content: space-between;
    margin-right: 3rem;
    align-items: center;
    gap: 1rem;
}

.modal-image-description {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.modal-dialog-content img {
    max-height: 100vh;
    width: auto;
    max-width: 100%; /* Ensure the image adjusts within modal boundaries */
    border-radius: 8px;
    margin-top: 1rem; /* Spacing between top and image */
}

.modal-dialog-content h2 {
    font-size: 1.8rem; /* Large header for better focus */
    font-weight: 800;
    color: #375945;
    margin-bottom: 1rem; /* Space between title and tags */
}

.modal-dialog-content .tags {
    margin-bottom: 1rem; /* Space between tags and image */
}

.modal-dialog-content p {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    margin-top: 1rem; /* Space between image and description */
}

.card-vote-buttons,
.modal-vote-buttons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.8rem 0; /* Add some spacing */
}

.vote-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vote-icon {
  font-size: 1.5rem;
  color: #375945;
}

.vote-count {
  font-size: 1rem;
  color: #555;
}

.modal-header-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media (max-width: 768px) {
    .assignment-card {
      width: 100%; /* Take the full width of the screen */
      height: auto; /* Allow height to adjust naturally */
      padding: 1rem; /* Reduce padding for smaller screens */
      box-shadow: none; /* Optional: Remove shadow for a cleaner mobile design */
      gap: 0.5rem; /* Reduce spacing between elements */
    }
  
    .card-header {
      flex-direction: column; /* Stack title, buttons, and tags vertically */
      align-items: flex-start; /* Align content to the left */
      gap: 0.8rem;
    }
  
    .header-left h3 {
      font-size: 1.2rem; /* Reduce font size for smaller screens */
    }
  
    .tags {
      gap: 0.4rem; /* Reduce spacing between tags */
      margin-top: 0.5rem;
      overflow-x: scroll;
      max-height: '20px'
    }
  
    .tag {
      font-size: 0.7rem; /* Reduce tag font size */
      padding: 0.2rem 0.5rem; /* Adjust padding for smaller tags */
    }
  
    .card-content {
      flex-direction: column; /* Stack image and description vertically */
      align-items: flex-start; /* Center-align content */
      gap: 1rem;
      overflow: scroll;
    }
  
    .assignment-image {
      width: auto; /* Let the image take full width */
      max-width: 300px; /* Limit the maximum width */
      height: 100%; /* Maintain aspect ratio */
      max-height: 1000px; /* Prevent image from getting too large */
    }
  
    .assignment-description {
      text-align: center; /* Center-align text for a clean mobile look */
      font-size: 0.85rem; /* Reduce font size slightly */
    }
  
    .view-button {
      font-size: 0.9rem; /* Reduce font size */
      padding: 0.4rem 0.8rem; /* Adjust padding */
    }
  
    .save-icon {
      font-size: 1.5rem; /* Reduce icon size */
    }
  
    .modal-background-overlay {
      padding: 1rem; /* Reduce padding for smaller screens */
    }
  
    .modal-dialog-content {
      max-width: 90%; /* Limit modal width */
      max-height: 80vh; /* Limit modal height */
      padding: 1rem; /* Adjust padding */
    }
  
    .modal-dialog-header {
      flex-direction: column; /* Stack title and buttons vertically */
      align-items: flex-start; /* Align to the left */
      gap: 0.5rem;
    }
  
    .modal-dialog-content img {
      max-width: 100%; /* Image takes up full modal width */
      height: auto; /* Maintain aspect ratio */
      max-height: 200px; /* Limit the image height */
    }
  
    .modal-dialog-content h2 {
      font-size: 1.5rem; /* Adjust font size for smaller screens */
    }
  
    .modal-dialog-content p {
      font-size: 0.9rem; /* Reduce text size for smaller screens */
    }
  
    .card-vote-buttons,
    .modal-vote-buttons {
      justify-content: center; /* Center-align vote buttons */
      gap: 1rem; /* Add spacing between buttons */
    }
  
    .vote-icon {
      font-size: 1.2rem; /* Reduce icon size */
    }
  
    .vote-count {
      font-size: 0.9rem; /* Adjust font size */
    }
  }
  
