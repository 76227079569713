.sidebar {
    height: 100%;
    min-height: 100vh;
    background-color: #375945 !important;
    border: none !important;
    box-shadow: none !important;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .sidebar .ant-menu {
    background-color: transparent;
    border: none !important;
    padding: 0;
    margin: 0;
  }
  
  .sidebar .ant-menu-item {
    font-size: 14px;
    border: none !important;
  }
  