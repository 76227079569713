.payment-container {
    font-family: 'Mulish', sans-serif;
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .payment-back-button {
    background-color: #375945;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 0.6rem 1rem;
    cursor: pointer;
    font-size: 1.1rem;
    align-self: flex-start;
  }

  .payment-container h1 {
    font-size: 2rem;
    font-weight: 700;
    color: #375945;
    margin-bottom: 1rem;
  }
  
  .payment-form-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 3rem;
  }
  
  .payment-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
  
  .payment-header {
    font-size: 1.25rem;
    font-weight: 700;
    color: #375945;
  }
  
  .payment-form label {
    font-size: 0.95rem;
    font-weight: 500;
    color: #555;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  
  .payment-form input,
  .payment-form select {
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 0.5rem;
  }
  
  .card-details-row {
    display: flex;
    gap: 1rem;
  }
  
  .card-details-row label {
    flex: 1;
  }
  
  .payment-button {
    background-color: #375945;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 0.6rem 1rem;
    cursor: pointer;
    font-size: 1.1rem;
  }
  
  .payment-button:hover {
    background-color: #2c4737;
  }
  
  .payment-summary {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    background-color: #f8f9fc;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .payment-summary-top {
    background-color: #C3D7CB;
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom: #375945 2px solid;
    padding: 3rem 0 2rem 0;
  }
  
  .tagline {
    font-size: 1.8rem;
    font-weight: 600;
    color: #375945;
    margin-bottom: 1rem;
  }

  .payment-summary-top img {
    width: 10vw;
    height: auto;
  }
  
  .plan-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
  
  .plan-details h2 {
    font-size: 1.8rem;
    font-weight: 700;
    color: #375945;
    margin-top: 1rem;
  }
  
  .plan-details .price-summary {
    font-size: 1.5rem;
    font-weight: 600;
    color: #375945;
  }
  
  .plan-details .price-summary span {
    font-size: 0.9rem;
    font-weight: 400;
    color: #666;
  }
  
  .plan-features {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: left;
    font-size: 0.95rem;
    font-weight: 500;
    color: #555;
    line-height: 1.5;
  }
  
  .plan-features li {
    margin-bottom: 0.8rem;
  }
  
  .balancing-guy {
    max-width: 60%;
    margin-top: 1rem;
    margin-bottom: 7.2rem;
  }  