.worksheets-container {
    display: flex;
    height: 100%;
    font-family: 'Mulish';
}

.title {
    padding: 40;
    font-weight: 'bold';
    font-size: 25px;
    color: #375945;
    margin-bottom: 20px;
}

.content-sections {
    display: flex;
    height: calc(100vh - 150px);
    gap: 7px;
    margin-top: 20px;
}

.list-section {
    flex: 1;
    background: #fff;
    padding-bottom: 20px;
    overflow-y: auto;
    max-width: 25%;
}

.list-title {
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 10px;
}

.worksheets-list {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.worksheet-item {
    padding: 10px;
    background-color: #f4f4f4;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Mulish', sans-serif;
    font-weight: 500;
    color: #323232;
}

.worksheet-item:hover {
    background-color: #508a63;
    color: white;
}

.creation-section {
    position: relative;
    flex: 1;
    color: #323232 !important;
}

.creation-title {
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #323232 !important;
    margin-bottom: 20px;
    padding-left: 20px;
}

.creation-content {
    font-family: 'Mulish', sans-serif;
    color: white !important;
    display: flex;
    gap: 10px;
    flex-direction: row;
    height: calc(100vh - 150px);
    overflow: hidden; /* Prevent any overflow of the child sections */
}

.available-elements {
    position: relative;
    flex: 1;
    background-color: #fff;
    overflow-y: auto; /* Enable scrolling within the section */
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex-grow: 1;
}

.worksheet-elements {
    flex: 1; /* Allow it to expand within the container */
    display: flex;
    flex-direction: column;
    gap: 10px; /* Add spacing between elements */
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: calc(100vh - 150px); /* Restrict the height to the viewport minus header/footer */
    background-color: transparent; /* Make it consistent with available-elements */
    padding: 10px;
    box-sizing: border-box;
}

.elements-title {
    font-family: 'Mulish', sans-serif;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 5px;
    color: #323232;
}

.elements-title-2 {
    font-family: 'Mulish', sans-serif;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 5px;
    margin-top: 12px;
    color: #323232;
}

.elements-grid {
    display: flex; /* Use flex for a single-row layout */
    flex-wrap: wrap; /* Allow wrapping for overflow */
    gap: 15px;
    justify-content: space-between; /* Spread elements evenly */
    overflow-y: visible; /* Disable vertical scrolling */
    width: 100%; /* Take full width of the parent */
    box-sizing: border-box;
}

.elements-list {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Add spacing between individual elements */
    width: 100%; /* Ensure it takes the full width of its container */
    box-sizing: border-box;
    color: #323232;
}

.available-elements {
    flex: none; /* Prevent auto-sizing */
    width: 30%; /* Adjust to make it thinner */
    max-width: 30%;
    max-height: calc(100% - 20px);
    overflow-y: auto; /* Enable scrolling for the entire section */
    background-color: transparent; /* Remove background */
    background: none;
    padding: 10px;
    border: none; /* Ensure no border styling */
}

.element-item {
    position: relative;
    width: 95%;
    display: flex;
    flex: 1 1 calc(100% - 15px);
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    text-align: center;
    font-family: 'Mulish', sans-serif;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    background-color: #f4f4f4;
    color: #508a63;
    height: 65px;
    max-width: 100%;
    margin-bottom: 2px;
    margin-left: 2px;
    margin-right: 2px;
    padding-top: 2px;
}

.element-item:hover {
    background-color: #508a63;
    color: white;
}

.element-list-item {
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #aed4ba;
    color: #508a63; /* Optional: Background for clarity */
    overflow: visible; /* Ensure no clipping */
    height: auto; /* Allow the height to adjust dynamically based on content */
    min-height: 85px; /* Set a minimum height */
}

.element-list-item:hover {
    background-color: #508a63;
    color: white;
}

.add-worksheet-button {
    white-space: normal;
    height: auto;
    min-height: 42px;
    background-color: #508a63 !important; /* Green button background */
    border-radius: 8px;
    font-weight: bold;
    color: white;
}

.add-worksheet-button:hover {
    background-color: #FFEBA3 !important;
    color: #323232 !important;
}

.non-selected {
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 15px;
}

.non-selected-image {
    flex: 1;
    padding: 30px 0px;
    margin: 25px;
    margin-bottom: 0;
    margin-top: 0;
    max-width: 30%;
}

.non-selected-text {
    align-items: center;
    padding-right: 40px;
}

.move-up-icon,
.move-down-icon,
.delete-icon-2 {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #323232;
    font-size: 16px;
    cursor: pointer;
    z-index: 10;
    transition: color 0.3s ease;
}

.move-up-icon:hover,
.move-down-icon:hover,
.delete-icon-2:hover {
    color: #466e54;
}

.move-up-icon {
    top: 35px;
}

.move-down-icon {
    top: 60px;
}

.action-buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;
}

.buttons {
    position: absolute;
    top: 0px;
    right: 0px;
}

.action-item {
    padding: 10px 20px;
    background-color: #FFEBA3;
    color: #323232;
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s ease;
    border-width: 0px;
}

.action-item:hover {
    background-color: #508a63;
    color: white;
}

.preview-action {
    background-color: #ffeba3;
    color: #000;
}

.preview-action:hover {
    background-color: #466e54;
    color: #fff;
}

.saved {
    background-color: #c3d7cb;
    color: #fff;
}

.saved:hover {
    background-color: #c3d7cb;
    color: #fff;
    cursor: default;
}

.save-action {
    background-color: #466e54;
    color: #fff;
}

.save-action:hover {
    background-color: #FFEBA3;
    color: #000;
}

.discard-action {
    background-color: #ffd232; /* Yellow for discard */
    color: #323232;
}

.discard-action:hover {
    background-color: #323232;
}

.delete-worksheet-button {
    cursor: 'pointer';
    margin-left: '10px';
    color: #fa541c;
}

.delete-worksheet-button:hover {
    color: red !important;
}

.editable-title {
    display: flex;
    align-items: center;
    gap: 10px;
}

.title-edit-container,
.title-display-container {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 28px;
    padding-top: 10px;
}

.title-input {
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #323232;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 5px 10px;
    outline: none;
    transition: border-color 0.3s ease;
}

.title-input:focus {
    border-color: #59846A;
}

.title-edit-icon,
.title-save-icon {
    cursor: pointer;
    font-size: 20px;
    color: #343434;
    transition: color 0.3s ease;
}

.title-edit-icon:hover,
.title-save-icon:hover {
    color: #466e54;
}

body, html, .ant-modal, .ant-modal * {
    font-family: 'YourExpectedFont', sans-serif !important;
}

@media (max-width: 768px) {
    .title {
        display: flex;
        justify-content: center;
    }

    .content-sections {
        flex-direction: column; /* Stack sections vertically */
        height: auto; /* Allow full page height */
    }

    .list-section {
        max-width: 100%;
        margin-bottom: 1rem;
    }

    .creation-section {
        max-width: 100%;
        margin-bottom: 1rem;
    }

    .creation-content {
        flex-direction: column; /* Stack elements vertically */
        gap: 20px; /* Add spacing between sections */
        height: auto; /* Allow full height */
        overflow: visible; /* Prevent clipping */
    }

    .worksheet-elements {
        max-height: none; /* Remove height restriction */
        height: auto; /* Allow height based on content */
        overflow-y: visible; /* Prevent vertical scrolling within this section */
        padding: 0; /* Optional: Adjust padding */
    }

    .available-elements {
        width: 100%;
        max-width: 100%;
        height: auto; /* Adjust height for mobile */
        overflow-y: visible; /* Allow content to flow naturally */
    }

    .element-item {
        width: 100%; /* Take full width of the container */
    }

    .non-selected {
        flex-direction: column; /* Stack image and text vertically */
        margin: 0;
        align-items: center; /* Center-align for mobile */
    }

    .non-selected-image {
        max-width: 80%; /* Reduce image size for better fit */
        margin-bottom: 1rem;
    }

    .action-buttons {
        flex-direction: column; /* Stack action buttons vertically */
        gap: 10px; /* Add spacing between buttons */
        width: 100%; /* Take full width */
    }

    .elements-grid {
        flex-direction: column;
    }

    .elements-list {
        width: 100%;
    }


    .element-list-item {
        width: 100%;
    }

    .non-selected-text {
        padding: 20px;
    }

    .buttons {
        position: relative;
        top: 0;
        right: 0;
    }

    .action-item {
        width: 100%;
    }

    .title-edit-container,
    .title-display-container {
        height: 40px;
        white-space: nowrap;
        overflow-x: auto;
        max-width: 300px;
    }

    .title-input {
        font-size: 20px;
    }

    .title-edit-icon,
    .title-save-icon {
        font-size: 24px;
    }

    .sidebar {
        position: fixed; /* Keep it fixed at the top-left corner */
        top: 0px; /* Adjust distance from the top */
        left: 0px; /* Adjust distance from the left */
        z-index: 1000; /* Ensure it stays above other components */
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    .sidebar button {
        background-color: #375945;
        color: white;
        border: none;
        border-radius: 8px;
        padding: 10px 15px;
        font-family: 'Mulish', sans-serif;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }
    
    .sidebar button:hover {
        background-color: #2c4737;
    }
    
    .toolbox-content {
        z-index: 1; /* Lower z-index to ensure it stays under the sidebar */
    }  
}